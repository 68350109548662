import React, { Component, useState } from "react";

import "./App.css";
import * as tmdLogic from "./Dc.js";
import * as tmdLogicAlt from "./Dc-alt.js";
import homePageHero from "./img/homepage-hero.mp4";
import UpperNavList from "./Components/UpperNavList.js";
import IntroBlock from "./Components/content/IntroBlock.js";
import FillPower from "./Components/FillPower.js";
import Species from "./Components/Species.js";
import Origin from "./Components/Origin.js";
import Cleanliness from "./Components/Cleanliness.js";
import DownContent from "./Components/DownContent.js";
import Treatment from "./Components/Treatment.js";
import Processing from "./Components/Processing.js";
import Certification from "./Components/Certification.js";
import CM from "./Components/ColorMigration.js";
import Care from "./Components/Care.js";
import Sustainability from "./Components/Sustainability.js";
import MainRight from "./Components/MainRight.js";
import { brandList } from "./Dc.js";
import { lotException, ddException } from "./LotException.js";
import SearchForm from "./Components/SearchForm";
import SignInScreen from "./Components/Auth";
//import AFDConditional from './Components/AFDConditional';

//firebase
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

//imagesloaded
import imagesLoaded from "imagesloaded";

//GA
//import ReactGA from 'react-ga';
//const DEFAULT_CONFIG = {
//trackingId: 'UA-128507146-1',
//debug: true,
//gaOptions: {
//cookieDomain: 'none',
//siteSpeedSampleRate: 100
//}
//};

//GA2023
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "G-TM2GNPDEBB"
};

TagManager.initialize(tagManagerArgs);

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBCBmQ1b29WiJFz77ZqM4tRDbJEY_JIIO4",
  authDomain: "trackmydown.firebaseapp.com",
  projectId: "trackmydown",
  storageBucket: "trackmydown.appspot.com",
  messagingSenderId: "186705064419",
  appId: "1:186705064419:web:972f7953b3055b75f6b0d6",
  measurementId: "G-XSWBSP3KGF",
});

const db = getFirestore(firebaseApp);
const firestore = getFirestore();

class TMDSpec extends React.Component {
  state = {
    inputText: "",
  };

  onValueChange = (e) => {
    this.setState((prevState) => ({
      inputText: e.target.value,
    }));
  };

  render() {
    return (
      <div className="tmdspec inner dataEntry">
        <div className="dataEntry-values">
          {Object.keys(this.props.data).map((e, index) => (
            <label key={index} className={`tmdValue ${e}`}>
              <span className="title">{e}</span>:
              <input
                className="value"
                name="name"
                type="text"
                placeholder={this.props.value.toString()}
              />
            </label>
          ))}
        </div>
      </div>
    );
  }
}

class TMDFieldBU extends React.Component {
  render() {
    return (
      <label className="tmdspec" htmlFor={this.props.name}>
        <span className="title">{this.props.name}</span>:
        <input
          className="value"
          name={this.props.name}
          type="text"
          placeholder={this.props.value.toString()}
          onChange={this.props.handleChange.bind(this.props.name)}
        />
      </label>
    );
  }
}

function ProcFac(props) {
  let activeLang = props.activeLang;
  let pFacility = props.loc;

  switch (activeLang) {
    case "fr":
      switch (pFacility) {
        case "Los Angeles":
          pFacility = "TRAITÉE DANS THE USA";
          break;
        case "Montreal":
          pFacility = "TRAITÉE DANS CANADA";
          break;
        case "China":
          pFacility = "TRAITÉE DANS CHINA";
          break;
        default:
          pFacility = "TRAITÉE DANS THE USA";
          break;
      }
      break;

    default:
      switch (pFacility) {
        case "Los Angeles":
          pFacility = "PROCESSED IN THE USA";
          break;
        case "Montreal":
          pFacility = "PROCESSED IN CANADA";
          break;
        case "China":
          pFacility = "PROCESSED IN CHINA";
          break;
        default:
          pFacility = "PROCESSED IN THE USA";
          break;
      }
      break;
  }

  return (
    <>
      <input
        type="radio"
        id="processing"
        name="tmd"
        value="PROCESSING"
        onChange={() => props.changeWord("PROCESSING")}
      />
      <label className="pr-list" htmlFor="processing">
        {pFacility}
      </label>
    </>
  );
}

function RDSCertified(props) {
  const isBrand = props.isBrand;
  const isGoal = props.isrds;
  const isGRS = props.isgrs;
  const isTreatment = props.treatment;
  
  {/* if Eddie Bauer */}
  {/* if (isRSD == "true" ) { */}
  {/* 
  <label className="cr-list" htmlFor="certifications">
    Responsibly Sourced Down / bluesign / OEKO-TEX
  </label> 
  */}
  
  if(isBrand == "0502" && isTreatment){
    return (
      <>
        <input
          type="radio"
          id="certifications"
          name="tmd"
          value="CERTIFICATIONS"
          onChange={() => props.changeWord("CERTIFICATIONS")}
        />
        <label className="cr-list" htmlFor="certifications">
          RESPONSIBLY SOURCED DOWN / bluesign 
        </label>
      </>
    );
  }else if(isBrand == "0502" && !isTreatment){
    return (
      <>
        <input
          type="radio"
          id="certifications"
          name="tmd"
          value="CERTIFICATIONS"
          onChange={() => props.changeWord("CERTIFICATIONS")}
        />
        <label className="cr-list" htmlFor="certifications">
          RESPONSIBLY SOURCED DOWN / bluesign / OEKO-TEX&reg; Standard 100
        </label>
      </>
    );
  }else if (isGoal == "true" && isTreatment) {
    return (
      <>
        <input
          type="radio"
          id="certifications"
          name="tmd"
          value="CERTIFICATIONS"
          onChange={() => props.changeWord("CERTIFICATIONS")}
        />
        <label className="cr-list" htmlFor="certifications">
          RDS / bluesign 
        </label>
      </>
    );
  }else if (isGoal == "true" && !isTreatment) {    
      return (
        <>
          <input
            type="radio"
            id="certifications"
            name="tmd"
            value="CERTIFICATIONS"
            onChange={() => props.changeWord("CERTIFICATIONS")}
          />
          <label className="cr-list" htmlFor="certifications">
            RDS / bluesign / OEKO-TEX&reg; Standard 100
          </label>
        </>
      );
  }else if (isGRS == "true" && isTreatment) {
    return (
      <>
        <input
          type="radio"
          id="certifications"
          name="tmd"
          value="CERTIFICATIONS"
          onChange={() => props.changeWord("CERTIFICATIONS")}
        />
        <label className="cr-list" htmlFor="certifications">
          GRS / bluesign
        </label>
      </>
    );
  }else if (isGRS == "true" && !isTreatment) {
    
  }else {
    return (
      <>
        <input
          type="radio"
          id="certifications"
          name="tmd"
          value="CERTIFICATIONS"
          onChange={() => props.changeWord("CERTIFICATIONS")}
        />
        <label className="cr-list" htmlFor="certifications">
          bluesign / OEKO-TEX&reg; Standard 100
        </label>
      </>
    );
  }
}

function contentClear(data) {
  //console.log('CLEARING:'+ data)
  document.querySelector("body").classList.add("wipeOne");
  document.querySelector("body").classList.remove("imgUp");

  setTimeout(function () {
    let posts = document.querySelectorAll(".il-add");
    let imgLoad = imagesLoaded(posts, { background: true });
    //console.log(posts);

    imgLoad.on("done", function (instance) {
      //console.log('DONE  - all images have been successfully loaded');
      document.querySelector("body").classList.add("imgUp");
      document.querySelector("body").classList.add("wipeLeft");

      setTimeout(function () {
        document.querySelector("body").classList.remove("wipeLeft");
        document.querySelector("body").classList.remove("wipeOne");
      }, 1260);
    });
  }, 250);

  //done?!
  document.querySelector("body").classList.add("add");
  //document.querySelector('body').classList.add('wipeLeft');

  /*
  let activeIL = document.querySelectorAll('.il-add');  
  
  if(activeIL >= 1){	  	  
    for(let i=0; i <= activeIL.length; i++){
	  console.log(activeIL)[i];  
	  activeIL[i].remove();
    }
  }
*/
}

class ContentHat extends React.Component {
  constructor() {
    super();
    this.lotHat = React.createRef();
  }

  state = {
    active: "",
  };

  componentDidMount() {
    this.lotHat.current.focus();
  }

  //certification logos
  handleChangee = (e) => {
    let radios = document.querySelectorAll(".content-summary input");
    let checkboxes = document.querySelectorAll(".ac-input");
    let contentBox = document.querySelector(".content-face");

    this.setState((prevState) => ({
      active: e.target.dataset.value,
    }));

    this.props.onChange("", e.target.dataset.value);
    document.querySelector("body").setAttribute("data-active", "");
    document.querySelector("body").dataset.active = e.target.dataset.value;

    //analytics
    //ReactGA.pageview(window.location.pathname + window.location.search +"#"+e.target.dataset.value);

    //contentHat
    for (let y = 0; y < radios.length; y++) {
      radios[y].checked = false;
      if (e.target.dataset.value == radios[y].value) {
        radios[y].checked = true;
      }
    }

    //contentFace
    for (let y = 0; y < checkboxes.length; y++) {
      checkboxes[y].parentNode.classList.remove("active");
      checkboxes[y].parentNode.classList.add("hide");
      checkboxes[y].checked = false;

      if (e.target.dataset.value == checkboxes[y].dataset.slug) {
        checkboxes[y].checked = true;
        contentBox.classList.add("active");
        checkboxes[y].parentNode.classList.remove("hide");
        checkboxes[y].parentNode.classList.add("active");
        checkboxes[y].focus();
      }
    }

    contentClear(e.target.dataset.value);
  };

  handleChange = (e) => {
    //console.log('ContentHat handleChange');

    //can i loop the inputs?
    let radios = document.querySelectorAll(".content-summary input");
    let checkboxes = document.querySelectorAll(".ac-input");
    let contentBox = document.querySelector(".content-face");

    if (e != "CERTIFICATIONS" && e != "PROCESSING") {
      e.target.focus();

      this.setState((prevState) => ({
        active: e.target.value,
      }));

      this.props.onChange("", e.target.value);
      document.querySelector("body").setAttribute("data-active", "");
      document.querySelector("body").dataset.active = e.target.value;

      //analytics
      //ReactGA.pageview(window.location.pathname + window.location.search +"#"+e.target.value);

      for (let y = 0; y < checkboxes.length; y++) {
        checkboxes[y].parentNode.classList.remove("active");
        checkboxes[y].parentNode.classList.add("hide");
        checkboxes[y].checked = false;

        if (e.target.value == checkboxes[y].dataset.slug) {
          checkboxes[y].checked = true;
          contentBox.classList.add("active");
          checkboxes[y].parentNode.classList.remove("hide");
          checkboxes[y].parentNode.classList.add("active");
        }
      }

      contentClear(e.target.value);
    } else {
      console.log(e);

      this.setState((prevState) => ({
        active: e,
      }));
      //this.props.onChange('', this.state.active);

      this.props.onChange("", e);
      document.querySelector("body").setAttribute("data-active", "");
      document.querySelector("body").dataset.active = e;

      //analytics
      //ReactGA.pageview(window.location.pathname + window.location.search +"#"+e);

      for (let y = 0; y < checkboxes.length; y++) {
        checkboxes[y].parentNode.classList.remove("active");
        checkboxes[y].parentNode.classList.add("hide");
        checkboxes[y].checked = false;

        if (e == checkboxes[y].dataset.slug) {
          checkboxes[y].checked = true;
          contentBox.classList.add("active");
          checkboxes[y].parentNode.classList.remove("hide");
          checkboxes[y].parentNode.classList.add("active");

          checkboxes[y].focus();
        }
      }
      contentClear(e);
    }

    /*
		
	      
	      //contentClear();
	      //imgChanger(this.value);
	    }else if(this.value == "LOT"){
		  //contentClear();  
		  //imgChanger(this.value);		 
		  //checkboxes[y].parentNode.classList.remove('hide');  
		  
		  //titleText.innerHTML = "fill power";
	      //valueText.innerHTML = "850";		  
	    }
		*/
  };

  fpTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "POUVOIR DE REMPLISSAGE";
      default:
        return "FILL POWER";
    }
  };
  fpTranslateVeri = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "VÉRIFIÉ À";
      default:
        return "VERIFIED AT";
    }
  };

  spTranslate = (down_type, activeLang) => {
    switch (activeLang) {
      case "fr":
        //is renu?
        switch (down_type) {
          case "Renu Blend":
            return "RENU Recycled Down";
          case "White Goose":
            return "L'OIE BLANCHE";
          case "Grey Goose":
            return "L'OIE CENDRÉ";
          case "White Duck":
            return "CANARD BLANC";
          case "Grey Duck":
            return "CANARD CENDRÉ";
          default:
            //"white goose":
            return "CANARD BLANC";
        }
      default:
        switch (down_type) {
          case "Renu Blend":
            return "RENU Recycled Down";
          default:
            return down_type;
        }
    }
  };
  clTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "HYPOALLERGÉNIQUE";
      default:
        return "HYPOALLERGENIC";
    }
  };
  coTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "PLUMULE";
      default:
        return "CLUSTER CONTENT";
    }
  };

  trTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "TRAITEMENT";
      default:
        return "TREATMENT";
    }
  };

  cmTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "NOTES SUR LA COULEUR + MIGRATION";
      default:
        return "NOTES ON COLOR + MIGRATION";
    }
  };

  caTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "SOINS";
      default:
        return "CARE";
    }
  };
  uwTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "ULTRAWHT FR";
      default:
        return "ULTRAWHT";
    }
  };
  suTranslate = (e, activeLang) => {
    switch (activeLang) {
      case "fr":
        return "L'IMPACT ENVIRONNEMENTAL";
      default:
        return "ENVIRONMENTAL IMPACT";
    }
  };

  locationID = (e, activeLang) => {
    //Generate Titles : European, Hungarian, Chinese
    switch (activeLang) {
      case "fr":
        switch (e) {
          case "Hungary":
            return "HUNGARIAN ORIGINE";
          case "Europe":
            return "EUROPEAN ORIGINE";
          default:
            return "CHINESE ORIGINE";
        }
      default:
        switch (e) {
          case "Hungary":
            return "HUNGARIAN ORIGIN";
          case "Europe":
            return "EUROPEAN ORIGIN";
          default:
            return "CHINESE ORIGIN";
        }
    }
  };

  render() {
    let brandPrefix = this.props.prefix;
    let brandPrefixAlt = this.props.prefixAlt;
    let linky;
    let brandNamePrint;
    let brandLogoPrint;

    !this.props.brandLogoAlt
      ? (brandLogoPrint = false)
      : (brandLogoPrint = true);

    if (this.props.brandNameAlt != undefined) {
      brandNamePrint = this.props.brandNameAlt;
      linky = "//trackmydown.com/img/_brands/" + brandPrefixAlt + ".svg";
    } else {
      brandLogoPrint = true;
      brandNamePrint = this.props.brandName;
      linky = "//trackmydown.com/img/_brands/" + brandPrefix + ".svg";
    }

    //show logo??
    if (this.props.brandLogo) {
      if (this.props.brandLogoAlt != undefined) {
        brandLogoPrint = this.props.brandLogoAlt;
      }
    } else {
      brandLogoPrint = false;
    }

    let ddZero;

    if (this.props.prefix == "0404") {
      ddException(this.props.verified_fp).then(
        (result) => {
          console.log(result);
          ddZero = result.vfp;
        },
        (result) => {
          //FAIL
          console.log("Error");
        }
      );
    }

    return (
      <div className="content-hat">
        <div className="content-title">
          {brandLogoPrint ? (
            this.props.brandLink != undefined ? (
              <a href={"//" + this.props.brandLink} target="_blank">
                <img src={linky} alt={brandNamePrint} />
              </a>
            ) : (
              <img src={linky} alt={brandNamePrint} />
            )
          ) : this.props.brandLink != undefined ? (
            <a href={"//" + this.props.brandLink}>
              <p>{brandNamePrint}</p>
            </a>
          ) : (
            <p>{brandNamePrint}</p>
          )}
        </div>
        <div className="content-summary">
          <form>
            <input
              type="radio"
              id="lot"
              name="tmd"
              value="LOT"
              defaultChecked="true"
              onChange={this.handleChange}
              ref={this.lotHat}
            />
            <label htmlFor="lot">
              LOT N<sup>O</sup>{" "}
              {this.props.display_lotAlt != undefined
                ? this.props.display_lotAlt
                : this.props.display_lot}
            </label>

            {!ddZero ? (
              <>
                <input
                  type="radio"
                  id="fp"
                  name="tmd"
                  value="FP"
                  onChange={this.handleChange}
                />
                <label htmlFor="fp">
                  {this.props.stated_fp}{" "}
                  {this.fpTranslate(null, this.props.activeLang)} /{" "}
                  {this.fpTranslateVeri(null, this.props.activeLang)}{" "}
                  {this.props.verified_fp}{" "}
                </label>
              </>
            ) : null}

            <input
              type="radio"
              id="species"
              name="tmd"
              value="SPECIES"
              onChange={this.handleChange}
            />
            <label htmlFor="species">
              {this.spTranslate(this.props.down_type, this.props.activeLang)}
            </label>

            {this.props.down_type === "Renu Blend" ||
            this.props.oFlag != undefined ? null : (
              <>
                <input
                  type="radio"
                  id="origin"
                  name="tmd"
                  value="ORIGIN"
                  onChange={this.handleChange}
                />
                <label htmlFor="origin">
                  {this.locationID(this.props.origin[0], this.props.activeLang)}{" "}
                </label>
              </>
            )}

            <input
              type="radio"
              id="cleanliness"
              name="tmd"
              value="CLEANLINESS"
              onChange={this.handleChange}
            />
            <label htmlFor="cleanliness">
              {this.clTranslate(null, this.props.activeLang)}
            </label>

            <input
              type="radio"
              id="content"
              name="tmd"
              value="CONTENT"
              onChange={this.handleChange}
            />
            <label htmlFor="content">
              {this.props.down_cluster}%{" "}
              {this.coTranslate(null, this.props.activeLang)}
            </label>

            {this.props.treatment != false ? (
              <>
                <input
                  className="tr-list"
                  type="radio"
                  id="treatment"
                  name="tmd"
                  value="TREATMENT"
                  onChange={this.handleChange}
                />
                <label htmlFor="treatment">
                  {this.props.treatment}{" "}
                  {this.trTranslate(null, this.props.activeLang)}
                </label>
              </>
            ) : null}

            {/*
			  { this.props.ultrawht != undefined ? <>
			  <input type="radio" id="ultrawht" name="tmd" value="ULTRAWHT" 
			    onChange={this.handleChange} 
			  />
			  <label htmlFor="ultrawht">{ this.uwTranslate(null, this.props.activeLang)}</label>
			  </> : null }
			  */}
            <ProcFac
              loc={
                this.props.facility != "" ? this.props.facility : "Los Angeles"
              }
              value="PROCESSING"
              changeWord={this.handleChange}
              activeLang={this.props.activeLang}
              //inputPrRef={this.prHat}
            />

            <RDSCertified              
              isBrand={this.props.brand}
              isrds={this.props.rds.toString()}
              treatment={this.props.treatment}
              isgrs={
                this.props.down_type == "Renu Blend"
                  ? true.toString()
                  : false.toString()
              }
              value="CERTIFICATIONS"
              changeWord={this.handleChange}
            />

            <input
              type="radio"
              id="color-migration"
              name="tmd"
              value="CM"
              onChange={this.handleChange}
            />
            <label htmlFor="color-migration">
              {this.cmTranslate(null, this.props.activeLang)}
            </label>

            <input
              type="radio"
              id="sustainability"
              name="tmd"
              value="SUSTAINABILITY"
              onChange={this.handleChange}
            />
            <label htmlFor="sustainability">
              {this.suTranslate(null, this.props.activeLang)}
            </label>

            <input
              type="radio"
              id="care"
              name="tmd"
              value="CARE"
              onChange={this.handleChange}
            />
            <label htmlFor="care">
              {this.caTranslate(null, this.props.activeLang)}
            </label>
          </form>

          <div
            isrds={this.props.rds.toString()}
            isgrs={
              this.props.down_type == "Renu Blend"
                ? true.toString()
                : false.toString()
            }
            onClick={this.handleChangee}
            data-value="CERTIFICATIONS"
            className={
              this.props.rds == "Renu Blend"
              ? "cert-grid rds"                
                : this.props.rds
                ? "cert-grid rds"
                : this.props.down_type == "Renu Blend"
                ? "cert-grid grs"
                : "cert-grid"
            }
          >
            { //change to this.props.brand
              this.props.rds && this.props.brand != "0502" ? (
              <div data-value="CERTIFICATIONS" className="rds"></div>
            ) : null}
            {this.props.down_type == "Renu Blend" ? (
              <div data-value="CERTIFICATIONS" className="grs"></div>
            ) : null}
            <div data-value="CERTIFICATIONS" className="bluesign"></div>
            {this.props.aafa ? (
              <div value="CERTIFICATIONS" className="aafa"></div>
            ) : null}
            { //change to this.props.brand
              !this.props.treatment ? (
              <div data-value="CERTIFICATIONS" className="oeko"></div>
            ) : null}            
          </div>
        </div>
      </div>
    );
  }
}

class ContentFace extends React.Component {
  //console.log('contentFACEEE '+ e);

  handleChange = (event, id) => {
    let radios = document.querySelectorAll(".content-summary input");
    let checkboxes = document.querySelectorAll(".content-ac");

    for (let y = 0; y < checkboxes.length; y++) {
      checkboxes[y].classList.remove("active");
      checkboxes[y].classList.add("hide");
      checkboxes[y].checked = false;
    }

    document.querySelector("body").className = "";
    document.querySelector("body").className = "loaded";
    document.querySelector("body").dataset.active = "lot";

    document.querySelector(".content-face").classList.remove("active");

    //handle input
    radios[0].checked = true;

    this.props.onClose("", "derp");
    this.props.onChange("", id);
  };

  render() {
    return (
      <div className="content-face">
        <FillPower
          activeLang={this.props.activeLang}
          changeWord={this.handleChange.bind(this)}
          vFP={this.props.verified_fp}
          sFP={this.props.stated_fp}
        />
        <Species
          activeLang={this.props.activeLang}
          changeWord={this.handleChange}
          lotOrigin={this.props.origin}
          lotSpecies={this.props.down_type}
        />
        <Origin
          activeLang={this.props.activeLang}
          changeWord={this.handleChange}
          lotOrigin={this.props.origin[0]}
          lotSpecies={this.props.down_type}
        />
        <Cleanliness
          changeWord={this.handleChange}
          activeLang={this.props.activeLang}
          t={this.props.turbidity}
          o={this.props.oxygen}
        />
        <DownContent
          changeWord={this.handleChange}
          activeLang={this.props.activeLang}
          brand={this.props.brand}
          dc={this.props.down_cluster}
          lf={this.props.land_fowl}
          df={this.props.down_fiber}
          bf={this.props.broken_feather}
          ff={this.props.feather_fiber}
          q={this.props.quill}
          wf={this.props.waterfowl_feather}
          r={this.props.residue}
          report={this.props.rptLink}
        />
        {/*
	      <UltraWht 
	        activeLang = {this.props.activeLang}
	        changeWord = {this.handleChange}	        
	        uw		   = {this.props.ultrawht == true ? true : false }
	      />*/}
        <Treatment
          activeLang={this.props.activeLang}
          changeWord={this.handleChange}          
          apply={this.props.treatment}
        />
        <Processing
          activeLang={this.props.activeLang}
          changeWord={this.handleChange}
          loc={this.props.facility != "" ? this.props.facility : "Los Angeles"}
        />
        <Certification
          display_lot={this.props.display_lot}        
          treatment={this.props.treatment}        
          brand={this.props.prefix}
          changeWord={this.handleChange}
          activeLang={this.props.activeLang}
          rds={this.props.rds}
          grs={this.props.down_type === "Renu Blend" ? true : false}
        />
        <CM changeWord={this.handleChange} activeLang={this.props.activeLang} />
        <Care
          changeWord={this.handleChange}
          activeLang={this.props.activeLang}
        />
        <Sustainability
          activeLot={this.props.display_lot}
          changeWord={this.handleChange}
          activeLang={this.props.activeLang}
        />
      </div>
    );
  }
}

class TMDField extends React.Component {
  render() {
    {
      if (this.props.name == "rds" || this.props.name == "recycled") {
        return (
          <label className="tmdspec check" htmlFor={this.props.name}>
            <span className="title">{this.props.name}</span>:
            <input
              className="value"
              name={this.props.name}
              type="checkbox"
              defaultChecked={this.props.value}
              onChange={this.props.handleChange.bind(this.props.name)}
            />
          </label>
        );
      } else if (this.props.name == "treatment") {
        return (
          <label className="tmdspec treatment" htmlFor={this.props.name}>
            <span className="title">treatment</span>:
            <select
              value={this.props.value}
              onChange={this.props.handleChange.bind(this.props.name)}
            >
              <option key="901" value="none">
                none
              </option>
              <option key="902" value="HyperDry">
                HyperDRY
              </option>
              <option key="903" value="BioDown">
                BioDOWN
              </option>
              <option key="904" value="UltraWht">
                UltraWHT
              </option>
              <option key="905" value="ThermaDown">
                ThermaDOWN
              </option>
            </select>
          </label>
        );
      } else {
        let required = true;
        if (this.props.name != "prefix") {
          required = false;
        }
        let placeHolderClean;

        if (
          this.props.name == "quill" ||
          this.props.name == "down_cluster" ||
          this.props.name == "down_fiber" ||
          this.props.name == "feather_fiber" ||
          this.props.name == "waterfowl_feather" ||
          this.props.name == "broken_feather" ||
          this.props.name == "land_fowl" ||
          this.props.name == "residue"
        ) {
          //placeHolderClean = this.props.value.toFixed(1);
          placeHolderClean = this.props.value.toString();
        } else {
          placeHolderClean = this.props.value.toString();
        }

        return (
          <label className="tmdspec" htmlFor={this.props.name}>
            <span className="title">{this.props.name}</span>:
            <input
              className="value"
              required={required}
              name={this.props.name}
              type="text"
              placeholder={placeHolderClean}
              onChange={this.props.handleChange.bind(this.props.name)}
            />
          </label>
        );
      }
    }
  }
}

const tmdCard = React.createElement("section", {
  id: "tmdCard",
  title: "This is a title",
});

let specialOftheDay;
let singleLot;
let str;
let prodFac;
let derpObj = {};

async function prodFacility(query) {
  // console.log("prodFacility");
  singleLot = query;

  if (query.length > 5) {
    singleLot = query.slice(4, 5);
    if (!isNaN(+singleLot.charAt(0))) {
      str = "la";
    } else if (singleLot.charAt(0).toUpperCase() === "M") {
      str = "mt";
    } else {
      str = "cn";
    }
    return str;
  } else if (query.length > 4) {
    singleLot = query.slice(4);

    if (!isNaN(+singleLot.charAt(0))) {
      str = "la";
    } else if (singleLot.charAt(0).toUpperCase() === "M") {
      str = "mt";
    } else {
      str = "cn";
    }
    return str;
  } else {
    //invalid lot : not enough digits
  }
}

async function readSD(query) {
  console.log("READ??");

  //facility collection
  prodFac = await prodFacility(query);
  specialOftheDay = doc(firestore, prodFac + "/" + query);
  const mySnapshot = await getDoc(specialOftheDay);

  if (mySnapshot.exists()) {
    //display info from firestore
    //console.log("LOT EXISTS!");
    const docData = mySnapshot.data();
    //pulling from Firestore
    const cleanDocData = {
      prefix: docData.prefix,
      production_lot: docData.production_lot,
      display_lot: docData.display_lot,
      origin: [docData.origin],
      origin_percent: docData.origin_percent,
      down_type: docData.down_type,
      facility: docData.facility,
      rds: docData.rds,
      recycled: docData.recycled,
      treatment: docData.treatment,
      rptLink: docData.rptLink,
      stated_fp: docData.stated_fp,
      verified_fp: docData.verified_fp,
      down_cluster: docData.down_cluster,
      down_fiber: docData.down_fiber,
      feather_fiber: docData.feather_fiber,
      waterfowl_feather: docData.waterfowl_feather,
      broken_feather: docData.broken_feather,
      quill: docData.quill,
      land_fowl: docData.land_fowl,
      residue: docData.residue,
      oxygen: docData.oxygen,
      turbidity: docData.turbidity,
    };
    return cleanDocData;
  } else {
    // doc.data() will be undefined in this case
    fetch(`https://v1.trackmydown.com/blendmixData.aspx?lotno=${query}`)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.MSG == "Not Found") {
          //not in v1 DataBase
          //console.log('review data entry in v1')
          //console.log(responseData);
        } else {
          importLotData(responseData);
        }
      })
      .catch((error) => {
        console.log("Error fetching and parsing data", error);
      });
  }
}

async function importLotData(query) {
  let cleanTurbidity = query.turbulity.split("M");
  cleanTurbidity = cleanTurbidity[0];

  let cleanTreatment = query.Treatment;
  if (cleanTreatment == "") {
    cleanTreatment = false;
  } else {
    cleanTreatment = cleanTreatment;
  }

  let cleanRDS = query.RDS;
  //console.log(cleanRDS);

  if (cleanRDS === undefined && typeof cleanRDS == "undefined") {
    cleanRDS = false;
  } else {
    cleanRDS = true;
  }

  let cleanRecycled = query.Recycled;
  if ((cleanRecycled = "No")) {
    cleanRecycled = false;
  } else {
    cleanRecycled = true;
  }

  let cleanCluster = query.cluster;
  cleanCluster = parseFloat(cleanCluster).toFixed(1);
  let cleanDown = query.down;
  cleanDown = parseFloat(cleanDown).toFixed(1);
  let cleanFeather = query.feather;
  cleanFeather = parseFloat(cleanFeather).toFixed(1);
  let cleanLandfowl = query.landfowl;
  cleanLandfowl = parseFloat(cleanLandfowl).toFixed(1);
  let cleanWaterfowl = query.waterfowl;
  cleanWaterfowl = parseFloat(cleanWaterfowl).toFixed(1);
  let cleanQuill = query.quill;
  cleanQuill = parseFloat(cleanQuill).toFixed(1);
  let cleanResidue = query.residue;
  cleanResidue = parseFloat(cleanResidue).toFixed(1);
  let cleanBrokenFeather = query.brokenfeather;
  cleanBrokenFeather = parseFloat(cleanBrokenFeather).toFixed(1);

  const docData = {
    display_lot: query.Display_Lot,
    prefix: query.Prefix,
    production_lot: query.Production_Lot,
    stated_fp: query.statedFP,
    verified_fp: query.verfiedFP,
    turbidity: cleanTurbidity,
    oxygen: query.oxgen,
    down_cluster: cleanCluster,
    down_fiber: cleanDown,
    feather_fiber: cleanFeather,
    waterfowl_feather: cleanWaterfowl,
    broken_feather: cleanBrokenFeather,
    quill: cleanQuill,
    land_fowl: cleanLandfowl,
    residue: cleanResidue,
    rptLink: query.RPTLink,
    facility: query.Facility,
    treatment: cleanTreatment,
    recycled: cleanRecycled,
    rds: cleanRDS,
    down_type: query.DownType,
    origin: query.origin,
    origin_percent: query.origin_percent,
  };

  //doc(firestore,'cn/L18FN549');
  //const specialOftheDay = doc(db,  'cn/1700L21FN3894');

  let dbString = prodFac + "/" + query.Display_Lot;

  dbString = dbString.toString();

  const specialOftheDay = doc(db, dbString);
  
  setDoc(specialOftheDay, docData);

  //console.log('import complete // displayData');
}

async function querySweep(query) {
  let minLength = 5;

  if (query.length >= minLength) {
    //console.log(v1ServerFetch(query));
    return readSD(query);
  } else {
    //"MSG":"Invalid lot #"
    //console.log("invalid lot");
  }
}

function preload(imageArray, index) {
  index = index || 0;
  if (imageArray && imageArray.length > index) {
    let img = new Image();

    img.onload = function () {
      preload(imageArray, index + 1);
    };

    img.src = imageArray[index];
  }
}

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      active: "LOT",
      brand: "",
      db: {},
      loading: true,
      lotError: "",
      lotNotFound: 0,
      link: false,
      searchText: "",
      searchValid: -1,
      flag: false,
      rds: "",
      aafa: false,
      lang: "en",
      draft: "",
      //reactGaInitialised: false,
      //configs: [DEFAULT_CONFIG]
    };
  }

  filteredConfigs = () => {
    const { configs } = this.state;
    return configs.filter(({ trackingId: id }) => id);
  };

  /*
  addConfig = () => {
    const { configs } = this.state;
    this.setState({
      configs: [configs, DEFAULT_CONFIG]
    });
  };
*/

  /*
  updateConfig = (configIndex, key, type, event) => {
    const { configs } = this.state;
    const config = configs[configIndex];
    let value;
    if (type === 'checkbox') {
      value = !config[key];
    } else {
      value = event.target.value;
    }
    const newConfig = {
      ...config,
      [key]: value
    };
    this.setState({
      configs: [
        ...configs.slice(0, configIndex),
        newConfig,
        ...configs.slice(configIndex + 1)
      ]
    });
  };
*/

  componentDidMount() {
    let mountedLot;
    let mountedLang;

    /*
	if (this.filteredConfigs().length === 0) {return;}
    const { configs } = this.state;
*/
    //ReactGA.initialize(configs);

    // Send initial test view
    //ReactGA.pageview(window.location.pathname + window.location.search);

    this.setState({ reactGaInitialised: true });

    for (
      var n = window.location.search.substring(1).split("&"), o = 0;
      o < n.length;
      o++
    ) {
      let t = n[o].split("=");
      if (
        decodeURIComponent(t[0]) == "lot" ||
        decodeURIComponent(t[0]) == "lotno"
      ) {
        mountedLot = decodeURIComponent(t[1]);
        this.setState((prevState) => ({
          lotError: mountedLot,
        }));
      }
      if (decodeURIComponent(t[0]) == "loc") {
        mountedLang = decodeURIComponent(t[1]);
        this.setState((prevState) => ({
          lang: mountedLang,
        }));
      }
    }

    if (mountedLot != undefined && mountedLot != "") {
      //TODO
      //setContactLink to include LotNumber
      if (mountedLot.length >= 7) {
        this.setState((prevState) => ({
          lotError: mountedLot,
          searchValid: true,
        }));

        //mountedLot can be found??
        //console.log("mountedLot");
                  
        lotException(mountedLot).then(async (result) => {
          this.lookUpLot("", mountedLot);  
        });
        
        // style input value
        //e.target.childNodes[1].value = "LOADING LOT INFO...";
      } else {
        //console.log('mountedLot! but less than 7')

        this.setState((prevState) => ({
          flag: true,
          lotError: mountedLot,
          lotNotFound: 1,
          searchValid: false,
        }));

        document.querySelector("body").classList.add("intro");
      }
    } else {
      //show HomePage
      document.querySelector("body").classList.add("intro");
      //ReactGA.pageview(window.location.pathname+window.location.search);
    }
  }

  importALottaData = (query) => {
    let cleanTurbidity = query.turbulity.split("M");
    cleanTurbidity = cleanTurbidity[0];

    let cleanTreatment = query.Treatment;
    if (cleanTreatment == "") {
      cleanTreatment = false;
    } else {
      //expedry
      if (cleanTreatment == "EXPEDRY") {
        cleanTreatment = "ExpeDRY";
      }
      //hyperdry
      if (cleanTreatment == "HyperDRY" || cleanTreatment == "hyperDRY" ) {
        cleanTreatment = "HyperDry";
      }
      let treatmentArray = [];
      treatmentArray.push(cleanTreatment);
      cleanTreatment = treatmentArray;
    }

    //let cleanRDS = query.RDS;
    let cleanRDS = true;

    if (cleanRDS === undefined && typeof cleanRDS == "undefined") {
      cleanRDS = false;
    } else {
      cleanRDS = true;
    }

    let cleanRecycled = query.Recycled;
    if ((cleanRecycled = "No")) {
      cleanRecycled = false;
    } else {
      cleanRecycled = true;
    }

    let cleanCluster = query.cluster;
    cleanCluster = parseFloat(cleanCluster).toFixed(1);
    let cleanDown = query.down;
    cleanDown = parseFloat(cleanDown).toFixed(1);
    let cleanFeather = query.feather;
    cleanFeather = parseFloat(cleanFeather).toFixed(1);
    let cleanLandfowl = query.landfowl;
    cleanLandfowl = parseFloat(cleanLandfowl).toFixed(1);
    let cleanWaterfowl = query.waterfowl;
    cleanWaterfowl = parseFloat(cleanWaterfowl).toFixed(1);
    let cleanQuill = query.quill;
    cleanQuill = parseFloat(cleanQuill).toFixed(1);
    let cleanResidue = query.residue;
    cleanResidue = parseFloat(cleanResidue).toFixed(1);
    let cleanBrokenFeather = query.brokenfeather;
    cleanBrokenFeather = parseFloat(cleanBrokenFeather).toFixed(1);

    {/*rds: cleanRDS*/}
    const docData = {
      display_lot: query.Display_Lot,
      prefix: query.Prefix,
      production_lot: query.Production_Lot,
      stated_fp: query.statedFP,
      verified_fp: query.verfiedFP,
      turbidity: cleanTurbidity,
      oxygen: query.oxgen,
      down_cluster: cleanCluster,
      down_fiber: cleanDown,
      feather_fiber: cleanFeather,
      waterfowl_feather: cleanWaterfowl,
      broken_feather: cleanBrokenFeather,
      quill: cleanQuill,
      land_fowl: cleanLandfowl,
      residue: cleanResidue,
      rptLink: query.RPTLink,
      facility: query.Facility,
      treatment: cleanTreatment,
      recycled: cleanRecycled,
      rds: true,
      down_type: query.DownType,
      origin: [query.origin],
      origin_percent: query.origin_percent,
    };

    //let dbString = prodFac+"/"+query.Display_Lot
    //console.log(docData);

    let dbString = "afd/" + prodFac + "/" + query.Display_Lot + "/verified";
    dbString = dbString.toString();

    const specialOftheDay = doc(db, dbString);
                
    setDoc(specialOftheDay, docData);

    console.log("import complete // displayData");
    this.lookUpLot(null, query.Display_Lot);
  };

  changeWord = (e) => {
    //console.log('changeWord!!');
  };

  handleClose = (e) => {};

  barTest = (e, param2) => {
    //console.log('barTest');
  };

  lookupLott = (e, param2) => {
    let cleanParam = "";
    if (param2 == undefined) {
      cleanParam = "LOT";
    } else {
      cleanParam = param2;
    }

    this.setState((prevState) => ({
      active: cleanParam,
    }));

    document.querySelector("body").classList.remove("addUp");
  };

  handleLotError = (param2) => {
    let inputTMD = document.querySelector(".search-form input");
    inputTMD.value = param2 + " INVALID LOT Nº";

    document.querySelector("body").classList.add("intro");

    this.setState((prevState) => ({
      lotError: param2,
      flag: true,
    }));

    //analytics
    //ReactGA.pageview(window.location.pathname + "&notfound=1");
  };

  processLot = (docData, param2) => {
    //document.querySelector("body").classList.add("loaded");
    setTimeout(function () {
      document.querySelector("body").classList.add("loaded");
    }, 1000);
    document.querySelector("body").classList.remove("intro");
    document.querySelector("body").dataset.active = "lot";

    tmdLogic.getUsefulContents(docData);

    let brandLink, brandName;
    brandList(docData.prefix).then(
      (result) => {
        brandLink = result.url;
        brandName = result.brandName;
        this.setState((prevState) => ({
          brand: result.prefix,
          brandLogo: result.logo,
          brandName: result.brandName,
          brandLink: brandLink,
        }));
      },
      (error) => {
        //console.log(error);
        brandName = "ALLIED FEATHER + DOWN";
        this.setState((prevState) => ({
          brand: "0000",
          brandLogo: true,
          brandName: brandName,
        }));
      }
    );

    this.setState((prevState) => ({
      searchText: param2.toUpperCase(),
      db: docData,
      rds: tmdLogic.rdsStatus,
    }));
  };

  processLotAlt = (docData, param2) => {
    //document.querySelector("body").classList.add("loaded");
    setTimeout(function () {
      document.querySelector("body").classList.add("loaded");
    }, 1000);
    document.querySelector("body").classList.remove("intro");
    document.querySelector("body").dataset.active = "lot";

    tmdLogic.getUsefulContentsAlt(docData);

    let brandLink, brandName;
    brandList(docData.brand.fields.prefix.stringValue).then(
      (result) => {
        brandLink = result.url;
        brandName = result.brandName;

        this.setState((prevState) => ({
          brand: result.prefix,
          brandLogo: result.logo,
          brandName: result.brandName,
          brandLink: brandLink,
        }));
      },
      (error) => {
        //console.log(error);
        brandName = "ALLIED FEATHER + DOWN";
        this.setState((prevState) => ({
          brand: "0000",
          brandLogo: true,
          brandName: brandName,
        }));
      }
    );

    let cleanTreatment;

    if (!docData.fields.treatment) {
      cleanTreatment =
        docData.fields.treatment.arrayValue.values[0].stringValue;
    } else {
      cleanTreatment = false;
    }

    let docDataa = {};

    Object.keys(docData.fields).forEach(function eachKey(key) {
      Object.keys(docData.fields[key]).forEach(function eachKey(keyy) {
        if (keyy == "arrayValue") {
          Object.keys(docData.fields[key][keyy]).forEach(function eachKey(
            keyyy
          ) {
            docData.fields[key][keyy][keyyy].forEach(
              (element) => (docDataa[key] = [element.stringValue])
            );
          });
        } else {
          docDataa[key] = docData.fields[key][keyy];
        }
      });
    });

    this.setState((prevState) => ({
      searchText: param2.toUpperCase(),
      db: docDataa,
      rds: tmdLogic.rdsStatus,
    }));
  };

  lookUpLot = (e, param2) => {
    //setContactLink
    param2 = param2.toUpperCase();
    document.querySelector('#root').dataset.brand = param2.substring(0, 4);
    document.querySelector("#root").dataset.email = param2;
    let randomCat;
    let randomNum;

    //alternate the landing hero
    if (sessionStorage.getItem("counter")) {
      let z = sessionStorage.getItem("counter");
      if (z % 2 == 0) {
        randomCat = "b";
      } else {
        randomCat = "a";
      }
      sessionStorage.setItem(
        "counter",
        Number(sessionStorage.getItem("counter")) + 1
      );
    } else {
      let randomNum = Math.floor(Math.random() * 100 + 1);
      let randomNumPlus = sessionStorage.getItem("counter") + 1;
      sessionStorage.setItem(
        "counter",
        Number(sessionStorage.getItem("counter")) + 1
      );
    }

    document.querySelector("#root").dataset.math = randomCat;
    let cleanParam2;
    
    //lotException
    lotException(param2).then(async (result) => {
      //origin fix
      let isPlainObject = function (obj) {
        return Object.prototype.toString.call(obj) === "[object Object]";
      };
      if (isPlainObject(result)) {
        //console.log(result);
        this.setState((prevState) => ({
          brandNameAlt: result.brandName,
          display_lotAlt: result.display_lot,
        }));
      } else {
        switch (result[0]) {
          case "brandName":
            if (result[2] != undefined) {
              this.setState((prevState) => ({
                brandNameAlt: result[1],
                brandPrefixAlt: result[2],
                brandLogoAlt: result[3],
              }));
            } else {
              this.setState((prevState) => ({
                brandNameAlt: result[1],
              }));
            }
            break;

          case "display_lot":
            this.setState((prevState) => ({
              display_lotAlt: result[1],
            }));
            break;
            
          case "origin":
            this.setState((prevState) => ({
              oFlag: true,
            }));
            break;

          case "redirect":
            setTimeout(function () {
              window.location.href = "./" + result[1];
            }, 100);
            return;
            break;
          
          case "kjuss":          
          setTimeout(function () {
            window.location.href = "./" + result[1];
          }, 100);
          this.setState((prevState) => ({
            display_lotAlt: "L23F0235",
          }));
          break;
        }
      }
    });

    //LotNumber via Local Storage
    let localTMDLot = sessionStorage.getItem(param2);

    /*
	if(JSON.parse(localTMDLot) != null){		
	  if(JSON.parse(localTMDLot).display_lot == param2){	   
	    let docData = JSON.parse(localTMDLot)		  		  	   		  	    	    
	    
	    //analytics			  	  		  
	    const paramLocal = new URLSearchParams(window.location.search);
		if(paramLocal.has('nf')){
		  paramLocal.delete('nf');
		}
		
	    window.history.replaceState({}, '', `${window.location.pathname}?${paramLocal}`);					
	    //Set Pageview in analytics
		//ReactGA.pageview(window.location.pathname + window.location.search);				
				
		this.processLot(docData, param2);				
	    return;
	  }
	}
	*/

    prodFacility(param2).then(
      async (result) => {
        param2 = param2.toUpperCase();
        prodFac = result;
        const docRef = doc(db, "afd/" + result + "/" + param2 + "/verified/");

        try {
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            //success
            setTimeout(function () {
              document.querySelector("body").classList.add("loaded");
            }, 1000);
                        
            document.querySelector("body").classList.remove("intro");
            document.querySelector("body").dataset.active = "lot";

            let docData = docSnap.data();                        
            docData.rds = true;
                        
            this.processLot(docData, param2);

            //analytics
            const paramSuccess = new URLSearchParams(window.location.search);
            paramSuccess.delete("nf");
            window.history.replaceState(
              {},
              "",
              `${window.location.pathname}?${paramSuccess}`
            );

            //ReactGA.pageview(window.location.pathname + "?lot=" + param2);
            //set pageview in analytics
            //ReactGA.pageview(window.location.pathname + window.location.search);
            window.dataLayer.push({
              event: "pageview",
              page: {
                url: window.location.pathname + window.location.search,
                title: param2,
              },
            });

            // Put the object into storage
            sessionStorage.setItem(
              docData.display_lot,
              JSON.stringify(docData)
            );

            return;
          } else {            
                        
            fetch(`https://v1.trackmydown.com/blendmixData.aspx?lotno=${param2}`)
            .then(res => {
              if (!res.ok) {console.log("v1 Server Flag")}              
              return res
            })
            .then(res => res.json())
            .then(data => {
            
              let inputTMD = document.querySelector(".search-form input");
              if (data.MSG == "Not Found") {
                //not in v1 DataBase
                this.setState((prevState) => ({
                //searchValid: true,
                lotError: param2,
                flag: true,
              }));
              const params = new URLSearchParams(window.location.search);
              params.set("nf", 1);
              window.history.replaceState(
                {},
                "",
                `${window.location.pathname}?${params}`
              );
        
              //analytics
              //ReactGA.pageview(window.location.pathname + window.location.search);
              window.dataLayer.push({
                event: "pageview",
                page: {
                  url: window.location.pathname + window.location.search,
                  title: param2,
                },
              });
        
              document.querySelector("body").classList.add("intro");
              document.querySelector(".search-form input").value =
                param2 + " NOT FOUND";
              setTimeout(function () {
                document.querySelector(".search-form input").value = "";
              }, 2000);
        
              //console.log(data);
              //console.log("v1DB??: "+ param2);
              } else {
                //importLotData(data);
                this.importALottaData(data);
              }
            }) // the data
            .catch(error => console.log(error)) // error handling                        
          }
        } catch (e) {
          //firebase error
          console.log("Error occurred", e);

          //GeoLocation Error Handling
          // https://www.trackmydown.com/core/fire.php/
          fetch(`https://www.trackmydown.com/core/fire.php?lot=${param2}`)
            .then((response) => response.json())
            .then((data) => {
              if (typeof data.message === "undefined") {
                console.log("geoSuccess");

                //success
                setTimeout(function () {
                  document.querySelector("body").classList.add("loaded");
                }, 1000);

                //console.log("LOT EXISTS!");
                document.querySelector("body").classList.remove("intro");
                document.querySelector("body").dataset.active = "lot";

                this.processLotAlt(data, param2);

                //analytics
                const paramSuccess = new URLSearchParams(
                  window.location.search
                );
                paramSuccess.delete("nf");
                window.history.replaceState(
                  {},
                  "",
                  `${window.location.pathname}?${paramSuccess}`
                );

                //ReactGA.pageview(window.location.pathname + "?lot=" + param2);
                //ReactGA.pageview(window.location.pathname + window.location.search);
                window.dataLayer.push({
                  event: "pageview",
                  page: {
                    url: window.location.pathname + window.location.search,
                    title: param2,
                  },
                });

                return;
              } else {
                //not in v1 DataBase
                console.log("geocatch:" + data.message);

                this.setState((prevState) => ({
                  //searchValid: true,
                  lotError: param2,
                  flag: true,
                }));
                const params = new URLSearchParams(window.location.search);
                params.set("nf", 1);
                window.history.replaceState(
                  {},
                  "",
                  `${window.location.pathname}?${params}`
                );

                //analytics
                //ReactGA.pageview(window.location.pathname + window.location.search);
                window.dataLayer.push({
                  event: "pageview",
                  page: {
                    url: window.location.pathname + window.location.search,
                    title: param2,
                  },
                });

                document.querySelector("body").classList.add("intro");
                document.querySelector(".search-form input").value =
                  param2 + " NOT FOUND";
                setTimeout(function () {
                  document.querySelector(".search-form input").value = "";
                }, 2000);

                return;
              }
            });
        }
      },
      (result) => {
        //FAIL
        console.log("Error");
      }
    );
  };

  handleLangChange = (name) => {
    //activeLANG
    console.log(name.target.value);
    this.setState((prevState) => ({
      lang: name.target.value,
    }));
  };

  handleVideo = (event) => {
    let vid = document.querySelector(".wallpaperVideo");
    let pauseButton = document.querySelector(".homePage-bg > button");

    if (vid.paused) {
      vid.play();
      pauseButton.classList.remove("pause");
    } else {
      vid.pause();
      pauseButton.classList.add("pause");
    }
  };

  searchValid = (data) => {
    this.setState((prevState) => ({
      searchValid: data,
    }));
  };

  searchDraft = (data) => {
    //console.log(data);
    data = data.toUpperCase();

    this.setState((prevState) => ({
      draft: data,
    }));
  };

  searchHelp = (event, data) => {
    if (true) {
      this.setState((prevState) => ({
        flag: !this.state.flag,
      }));
    } else {
      this.setState((prevState) => ({
        flag: !this.state.flag,
      }));
    }
  };

  render() {
    if (this.state.searchText != "") {
      return (
        <>
          <header>
            <a href="/">
              <h3>Track My Down</h3>
            </a>
          </header>
          <nav className="interiorNav">
            <ul>
              <UpperNavList
                lang={this.state.lang}
                searchText={this.state.searchText}
              />
              <li>
                <div
                  className="language"
                  role="radiogroup"
                  aria-labelledby="language-switcher1"
                >
                  <p className="hidden" id="language-switcher1">
                    Choose a language for this website
                  </p>
                  <select
                    value={this.state.selectValue}
                    onChange={this.handleLangChange.bind(this.value)}
                  >
                    <option value="en" defaultValue={true}>
                      EN
                    </option>
                    <option value="fr">FR</option>
                  </select>
                </div>
              </li>
            </ul>
          </nav>

          <section className="main-left">
            <div className="contentWrap">
              <ContentHat
                brand={this.state.brand}
                brandName={this.state.brandName}
                brandNameAlt={this.state.brandNameAlt}
                prefixAlt={this.state.brandPrefixAlt}
                brandLogo={this.state.brandLogo}
                brandLogoAlt={this.state.brandLogoAlt}
                brandLink={this.state.brandLink}
                activeLang={this.state.lang}
                activeSection={this.state.active}
                display_lotAlt={this.state.display_lotAlt}
                aafa={this.state.aafa}
                {...this.state.db}
                oFlag={this.state.oFlag}
                onChange={this.lookupLott}
              />
              <ContentFace
                activeLang={this.state.lang}
                activeSection={this.state.active}                
                {...this.state.db}
                onClose={this.handleClose}
                onChange={this.lookupLott}
              />
            </div>
          </section>

          <MainRight
            fpOpen={false}
            activeLang={this.state.lang}
            activeSection={this.state.active}
            display_lotAlt={this.state.display_lotAlt}
            {...this.state.db}
          />
          <small className="upper-credit">ISPO AWARD - GOLD WINNER 2019</small>
          <footer>a division of allied feather + down</footer>
        </>
      );
    } else {
      return (
        <>
          <section className="homePage">
            <div tabIndex="0" className="formWrap">
              <SearchForm
                onHelp={this.searchHelp}
                onSearch={this.lookUpLot}
                searchValid={this.searchValid}
                searchDraft={this.searchDraft}
                activeLang={this.state.lang}
                tabIndex="0"
              />
              <h1>TRACK MY DOWN</h1>

              {this.state.flag == true ? (
                <div className="homePage-help">
                  {this.state.lotError != "" ? (
                    <>
                      <p>
                        Lot Nº <span>{this.state.lotError}</span>{" "}
                        {this.state.searchValid == 1 ? (
                          <>cannot be found.</>
                        ) : (
                          <>
                            {" "}
                            is an invalid entry. <br /> Please review your entry
                            and try again.
                          </>
                        )}
                      </p>
                      <br />
                      <p>
                        If you believe you have reached this message in error{" "}
                        <a
                          href={
                            `mailto:help@trackmydown.com?subject=TMD Help | lot ` +
                            this.state.lotError +
                            " not found"
                          }
                        >
                          contact us
                        </a>
                      </p>
                    </>
                  ) : null}

                  {this.state.searchValid == -1 ? (
                    <>
                      <p>Having trouble locating your lot number?</p>
                      <p>We may be able to help.</p>
                      <p>
                        <a
                          href="mailto:help@trackmydown.com?subject=TMD Help"
                          tabIndex="0"
                        >
                          Message us
                        </a>
                      </p>
                    </>
                  ) : null}

                  {/* this.state.lotError = "system" && this.state.searchValid == 1 ? <>
			<p>Service Unavailable<br/>Our services are having trouble tracking your down at the moment.</p>
	  	    <br/>
	  	    <p>Please try again later or <a href={`mailto:help@trackmydown.com?subject=TMD Help | System` + this.state.searchText } tabIndex="0">contact us</a></p>
	  	    </> : null 
	  	  */}
                </div>
              ) : null}
            </div>

            <nav>
              <a href="//alliedfeather.com" target="_blank">
                <h2>Powered By ALLIED FEATHER + DOWN</h2>
              </a>
              <ul>
                <UpperNavList
                  lang={this.state.lang}
                  searchText={this.state.searchText}
                  lotError={this.state.lotError}
                />
                <li>
                  <div
                    className="language"
                    role="radiogroup"
                    aria-labelledby="language-switcher1"
                  >
                    <p className="hidden" id="language-switcher1">
                      Choose a language for this website
                    </p>
                    <select
                      value={this.state.selectValue}
                      onChange={this.handleLangChange.bind(this.value)}
                    >
                      <option value="en" defaultValue={true}>
                        EN
                      </option>
                      <option value="fr">FR</option>
                    </select>
                  </div>
                </li>
              </ul>
            </nav>

            <div className="homePage-bg" tabIndex="0">
              <button onClick={this.handleVideo}>
                <svg
                  className="play"
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m15 12.33-6 4.33V8l6 4.33Z" fill="currentColor" />
                </svg>
                <svg
                  className="pause"
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11 7H8v10h3V7ZM13 17h3V7h-3v10Z" fill="#f8f8f9" />
                </svg>
              </button>
              <video
                className="wallpaperVideo"
                src={homePageHero}
                poster="./img/homepage-hero.jpg"
                controls={false}
                preload={true.toString()}
                autoPlay={true}
                muted={true}
                loop={true}
              ></video>
            </div>

            <IntroBlock activeLang={this.state.lang} />

            <footer role="contentinfo" className="site-foot" tabIndex="1">
              <nav>
                <section className="wider">
                  <h3>A DIVISION OF ALLIED FEATHER + DOWN</h3>
                  <ul>
                    <li tabIndex="99">
                      <a
                        href="https://alliedfeather.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        AlliedFeather.com
                      </a>
                    </li>
                    <li tabIndex="99">
                      <a
                        href="https://alliedhomebedding.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        AlliedHomeBedding.com
                      </a>
                    </li>
                    <li tabIndex="99">
                      <a
                        href="https://www.linkedin.com/company/allied-feather-down"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </li>
                    <li tabIndex="99">
                      <a
                        href="https://www.instagram.com/alliedfeatheranddown/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        @alliedfeatheranddown
                      </a>
                    </li>
                  </ul>
                </section>
                <section className="">
                  <h3>culture</h3>
                  <ul>
                    <li tabIndex="99">
                      <a
                        href="https://intothecold.online"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        IntoTheCold.Online
                      </a>
                    </li>
                    <li tabIndex="99">
                      <a
                        href="https://www.instagram.com/zeronrth/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        @zeronrth
                      </a>
                    </li>
                  </ul>
                </section>
                <section className="">
                  <h3>contact us</h3>
                  <ul>
                    <li tabIndex="99">
                      <a href="mailto:help@trackmydown.com?subject=TMD Inquiry">
                        help@trackmydown.com
                      </a>
                    </li>
                    <li tabIndex="99">
                      6905 W Acco St, Montebello, CA
                      <br /> Los Angeles, CA 90640
                    </li>
                  </ul>
                </section>
              </nav>
            </footer>
          </section>
        </>
      );
    }
  }
}
